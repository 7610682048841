import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import FormInput from "../../form-input/form-input.component";
import { updateSubject } from "../../../actions/Subjects.action";
import { setAlert } from "../../../actions/alert";

import { Modal, Button } from 'antd';

const EditSubject = ({ savedSubject, updateSubject, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [subject, setSubject] = useState({
    id: savedSubject._id,
    subjectname: savedSubject.subjectname,
    about: savedSubject.about,
    klass : savedSubject.klass,
  });


  const { subjectname, about ,klass } = subject;
  const onChange = (e) =>
    setSubject({ ...subject, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    updateSubject(subject);
    setAlert("تم تعديل بيانات المادة بنجاح", "success");
    setIsModalVisible(false);
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <a onClick={showModal}>
        <FontAwesomeIcon icon={faEdit} />
      </a>

      <Modal title="تعديل علي المادة" visible={isModalVisible} 
      footer={[
        <Button key="back" onClick={handleCancel}>
          إلغاء الامر
        </Button>,
        <Button key="submit" type="primary"  onClick={onSubmit}>
          تعديل
        </Button>,
      ]}
      >
        <FormInput
              name="subjectname"
              type="text"
              handleChange={onChange}
              value={subjectname}
              label="إسم المادة"
              required
            />

            <FormInput
              name="about"
              type="text"
              handleChange={onChange}
              value={about}
              label="رمز المادة"
              required
            />
            <FormInput
              name="klass"
              type="text"
              handleChange={onChange}
              value={klass}
              label="المرحلة"
              required
            />

      </Modal>
    </>
  );
};

export default connect(null, { updateSubject, setAlert })(EditSubject);
