import React, { useEffect } from "react";
import 'antd/dist/antd.css';
import "react-big-calendar/lib/css/react-big-calendar.css"
import "aos/dist/aos.css";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import store from "./store";
import {useSelector} from "react-redux"
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
import PrivateRoute from "./components/routing/PrivateRoute";
import SuperPrivateRoute from "./components/routing/SuperPrivateRoute";
import Post from "./components/post/Post";
import Login from "./components/auth/Login";
import Signup from "./components/auth/homepage";
import Regester from "./components/auth/Register";

import MembersSubjects from "./dashBourd/Members/MembersSubjects";
// import SubjecrtsPage from "./dashBourd/Subjects/Subject.page";
// import subjectDetails from "./dashBourd/Subjects/subjectDetails";





// Admin DashBourd 
import SchoolsPage from "./pages/Admin/AdminPage";
import Admins from "./pages/Admin/admins"

//*********  Teacher Main Page ********//
//************************************* */

import TeacherPage from "./pages/Teachers/Homepage/Home";

// school Admin DashBourd
import NewsAndAdvs from "./pages/Teachers/SchoolAmin/News&Advs"
import AllUsers from "./pages/Teachers/SchoolAmin/AllUsers"
import AdminTable from "./pages/Teachers/SchoolAmin/table/tebles"
import ClassesPage from "./pages/Teachers/SchoolAmin/classes&courese"
import SchoolDetails from "./pages/Teachers/SchoolAmin/schoolDetails"

// Teacher DashBourd //
import TeacherDashbourd from "./pages/Teachers/teacherDashbourd/MainPage"
import Courses from "./pages/Teachers/teacherDashbourd/Courses/CoursesPage";
import Course from "./pages/Teachers/teacherDashbourd/Courses/CoursePage";
import LessonPage from "./dashBourd/Lessons/LessonPage";
import ExercisePage from "./dashBourd/Exercise/ExercisePage";

import AddExams from "./pages/Teachers/teacherDashbourd/Exams/nweTest";
import TeacherTable from "./pages/Teachers/teacherDashbourd/table/tebles";

import EditExam from "./pages/Teachers/teacherDashbourd/Exams/EditExam";
import DoExams from "./pages/Teachers/teacherDashbourd/Exams/DoExam";

//*********  Student Main Page ********//
//************************************* */

import StudentPage from "./pages/Students/Homepage/Home";

import StudentDashbourd from "./pages/Students/MainPage"
import CoursesStudent from "./pages/Students/Courses/CoursesPage";
import StudentTable from "./pages/Students/table/tebles";
import StudentCoursePage from "./pages/Students/Courses/CoursePage";



if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {   
     store.dispatch(loadUser());
  }, []);

const isAuth = useSelector(state => state.auth.isAuthenticated)

// const isLogin = () =>{
//   if(!isAuth){
//     return <Redirect to="/login" />
//   }
// }
 

  return (
  <Router>
    <Switch>

      <Route exact path="/" component={Login} />
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/regester" component={Regester} />


      <PrivateRoute exact path="/posts/:id" component={Post} />
      {/******* Adim Dashbourd Routes *********/}
      <PrivateRoute path="/admin/admins" exact component={Admins} />
      <PrivateRoute path="/admin/collage" exact component={SchoolsPage} />


      {/************ TeacherMainPage ****************/}
      {/* ***************************************** */}
      <PrivateRoute path="/teacher" exact component={TeacherPage} />
         {/* Teacher Routes  */}
      <PrivateRoute exact path="/teacher/:url" component={TeacherDashbourd} />
      <PrivateRoute exact path="/teacher/:url/table" component={TeacherTable} />
      <PrivateRoute exact path="/teacher/:url/courses" component={Courses} />
      <PrivateRoute exact path="/teacher/:url/courses/:id" component={Course} />
      <PrivateRoute exact path="/teacher/:url/Lessons/:id" component={LessonPage} />
      <PrivateRoute exact path="/teacher/:url/exercise/:id" component={ExercisePage} />

      {/* School Admins  Dashbourd Routes*/}
      <PrivateRoute path="/department/:url" exact component={SchoolDetails} />        
      <PrivateRoute path="/department/:url/users" exact component={AllUsers} />        
      <PrivateRoute path="/department/:url/advs" exact component={NewsAndAdvs} />        
      <PrivateRoute path="/department/:url/classes" exact component={ClassesPage} /> 
      <PrivateRoute path="/department/:url/table" exact component={AdminTable} /> 
           
   
    
      <Route exact path="/teacher/courses/:id/createExam" component={AddExams} />
      <PrivateRoute exact path="/teacher/editexam/:id" component={EditExam} />
      <PrivateRoute exact path="/teacher/exam/:id" component={DoExams} />
      <PrivateRoute exact path="/teacher/posts/:id" component={Post} />
      

       {/************ StudentMainPage ****************/}
      {/* ***************************************** */}
      <PrivateRoute exact path="/student" component={StudentPage} />
      <PrivateRoute exact path="/student/:url" component={StudentDashbourd} />
      <PrivateRoute exact path="/student/:url/table" component={StudentTable} />
      <PrivateRoute exact path="/student/:url/courses" component={CoursesStudent} />
      <PrivateRoute exact path="/student/:url/courses/:id" component={StudentCoursePage} />
      <PrivateRoute exact path="/student/:url/Lessons/:id" component={LessonPage} />
      <PrivateRoute exact path="/student/:url/exercise/:id" component={ExercisePage} />



     {/* {isLogin()} */}
      {/* <PrivateRoute path="/advs" exact component={Demo} />
      <PrivateRoute path="/users" exact component={AllUsers} />
      <PrivateRoute path="/news" exact component={newsPage} />
      <PrivateRoute path="/department" exact component={DepartmentsPage} />
      <PrivateRoute path="/schools" exact component={SchoolsPage} />
      <PrivateRoute path="/offices" exact component={OfficesPage} />
      <PrivateRoute path="/students" exact component={StudentsPage} />
      <PrivateRoute path="/members" exact component={MembersPage} />
      <PrivateRoute path="/members/:id" exact component={MembersSubjects} />
      <PrivateRoute path="/subjects" exact component={SubjecrtsPage} />
      <PrivateRoute path="/subjects/:id" exact component={subjectDetails} />
      <PrivateRoute exact path="/posts/:id" component={Post} /> */}
    </Switch>
  </Router>
  );
};
export default App;


