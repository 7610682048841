export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const REMOVE_ERRORS = "REMOVE_ERRORS";
export const REMOVE_ERROR = "REMOVE_ERROR";
export const REMOVE_PROGRESS = "REMOVE_PROGRESS";
export const UPLOAD_PROGRESS = "UPLOAD_PROGRESS";

export const GET_SCHOOLS = "GET_SCHOOLS";
export const GET_SCHOOL = "GET_SCHOOL";
export const ADD_SCHOOL = "ADD_SCHOOL";
export const UPDATE_SCHOOL = "UPDATE_SCHOOL";
export const DELETE_SCHOOL = "DELETE_SCHOOL";
export const ERROR_SCHOOL = "ERROR_SCHOOL";


export const GET_STUDYYEARS = "GET_STUDYYEARS";
export const GET_STUDYYEAR = "GET_STUDYYEAR";
export const ADD_STUDYYEAR = "ADD_STUDYYEAR";
export const UPDATE_STUDYYEAR = "UPDATE_STUDYYEAR";
export const DELETE_STUDYYEAR = "DELETE_STUDYYEAR";
export const ERROR_STUDYYEAR = "ERROR_STUDYYEAR";

export const GET_ADVS = "GET_ADVS";
export const GET_ADV = "GET_ADV";
export const ADD_ADV = "ADD_ADV";
export const UPDATE_ADV = "UPDATE_ADV";
export const DELETE_ADV = "DELETE_ADV";
export const ERROR_ADV = "ERROR_ADV";

export const GET_NEWSS = "GET_NEWSS";
export const GET_NEWS = "GET_NEWS";
export const ADD_NEWS = "ADD_NEWS";
export const UPDATE_NEWS = "UPDATE_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";
export const ERROR_NEWS = "ERROR_NEWS";

export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const GET_DEPARTMENT = "GET_DEPARTMENT";
export const ADD_DEPARTMENT = "ADD_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const ERROR_DEPARTMENT = "ERROR_DEPARTMENT";

export const GET_OFFICES = "GET_OFFICES";
export const GET_OFFICE = "GET_OFFICE";
export const ADD_OFFICE = "ADD_OFFICE";
export const UPDATE_OFFICE = "UPDATE_OFFICE";
export const DELETE_OFFICE = "DELETE_OFFICE";
export const ERROR_OFFICE = "ERROR_OFFICE";

export const ADD_STUDENT = "ADD_STUDENT";
export const ERROR_STUDENT = "ERROR_STUDENT";
export const GET_STUDENTS = "GET_STUDENTS";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const UPDATE_STUDENT = "UPDATE_STUDENT";

export const GET_MEMBERS = "GET_MEMBERS";
export const ADD_MEMBER = "ADD_Member";
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const ERROR_MEMBER = "ERROR_MEMBER";
export const DELETE_MEMBER = "DELETE_MEMBER";
export const GET_MEMBER = "GET_MEMBER";

export const MEMBER_REGISTER_SUCCESS = "MEMBER_REGISTER_SUCCESS";
export const MEMBER_REGISTER_FAIL = "MEMBER_REGISTER_FAIL";

export const GET_SUBJECTS = "GET_SUBJECTS";
export const ADD_SUBJECT = "ADD_SUBJECT";
export const ERROR_SUBJECT = "ERROR_SUBJECT";
export const UPDATE_SUBJECT = "UPDATE_SUBJECT";
export const DELETE_SUBJECT = "DELETE_SUBJECT";
export const GET_EXAMS = "GET_EXAMS";
export const GET_FILES = "GET_FILES";
export const GET_SUBJECT = "GET_SUBJECT";
export const CLEAR_SUBJECT = "CLEAR_SUBJECT";
export const SUBJECT_FILTERED = "SUBJECT_FILTERED";
export const SUBJECT_FILTEREDـBY_CLASS = "SUBJECT_FILTEREDـBY_CLASS";

export const FILTER_STUDENTS = "FILTER_STUDENTS";

export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";
export const POST_ERROR = "POST_ERROR";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";
export const ADD_COMMENT = "ADD_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
export const UPDATE_POST = "UPDATE_POST";
export const UPDATE_COMMENT = "UPDATE_COMMENT";

export const ADD_FILE = "ADD_FILE";
export const ADD_EXAM = "ADD_EXAM";
export const GET_EXAM = "GET_EXAM";
export const DELETE_EXAM = "DELETE_EXAM";
export const UPDATE_EXAM = "UPDATE_EXAM";
export const EXAM_ERROR = "EXAM_ERROR";

export const FILE_ERROR = "FILE_ERROR";
export const DELETE_FILE = "DELETE_FILE";
export const UPDATE_FILE = "UPDATE_FILE";

export const GET_TSS = "GET_TSS";
export const TSS_ERROR = "TSS_ERROR";
export const ADD_TSS = "ADD_TSS";
export const UPDATE_TSS = "UPDATE_TSS";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const UPDATED_DATA = "UPDATED_DATA";

export const GET_ADMINS = "GET_ADMINS";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const ERROR_ADMIN = "ERROR_ADMIN";

export const ADD_SCHOOL_TO_MEMBER = "ADD_SCHOOL_TO_MEMBER";

export const GET_CLASSES = "GET_CLASSES";
export const ADD_CLASS = "ADD_CLASS";
export const UPDATE_CLASS = "UPDATE_CLASS";
export const ERROR_CLASS = "ERROR_CLASS";
export const DELETE_CLASS = "DELETE_CLASS";
export const FILTER_CLASSES = "FILTER_CLASSES";


export const GET_COURSES = "GET_COURSES";
export const ERROR_COURSE = "ERROR_COURSE";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const DELETE_COURSE = "DELETE_COURSE";
export const GET_COURSE = "GET_COURSE";
export const ADD_COURSE = "ADD_COURSE";
export const CLEAR_COURSE = "CLEAR_COURSE";
export const GET_OLDCOURSES = "GET_OLDCOURSES";
export const FILTER_COURSES = "FILTER_COURSES";



export const GET_TOPICS_BY_COURSE = "GET_TOPICS_BY_COURSE";
export const ERROR_TOPIC = "ERROR_TOPIC";
export const UPDATE_TOPIC = "UPDATE_TOPIC";
export const DELETE_TOPIC = "DELETE_TOPIC";
export const GET_TOPIC = "GET_TOPIC";
export const ADD_TOPIC = "ADD_TOPIC";
export const FILTER_TOPICS = "FILTER_TOPICS";
export const GET_OLDTOPICS = "GET_OLDTOPICS";


export const GET_LESSONS = "GET_LESSONS";
export const ERROR_LESSON = "ERROR_LESSON";
export const UPDATE_LESSON = "UPDATE_LESSON";
export const DELETE_LESSON = "DELETE_LESSON";
export const GET_LESSON = "GET_LESSON";
export const ADD_LESSON = "ADD_LESSON";
export const CLEAR_LESSON = "CLEAR_LESSON";

export const GET_EVENTS = "GET_EVENTS";
export const ERROR_EVENT = "ERROR_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const GET_EVENT = "GET_EVENT";
export const ADD_EVENT = "ADD_EVENT";

export const GET_EXERCISES = "GET_EXERCISES";
export const ERROR_EXERCISE = "ERROR_EXERCISE";
export const UPDATE_EXERCISE = "UPDATE_EXERCISE";
export const DELETE_EXERCISE = "DELETE_EXERCISE";
export const GET_EXERCISE = "GET_EXERCISE";
export const ADD_EXERCISE = "ADD_EXERCISE";
export const CLEAR_EXERCISE = "CLEAR_EXERCISE";
export const GET_FILES_EXERCISE = "GET_FILES_EXERCISE";



  export const HOMEWORK_ERROR  = "HOMEWORK_ERROR"
  export const DELETE_HOMEWORK  = "DELETE_HOMEWORK"
  export const GET_HOMEWORKS  = "GET_HOMEWORKS"
  export const ADD_HOMEWORK  = "ADD_HOMEWORK"