import axios from "axios";
import {
  GET_TSS,
  UPDATED_DATA,
  PROFILE_ERROR,
  ADD_TSS,
  REMOVE_PROGRESS,
  UPLOAD_PROGRESS,
  ADD_SCHOOL_TO_MEMBER
} from "./types";

// GET TSS
export const getTss = (tss) => async (dispatch) => {
  try {
    const res = await axios.get("/api/profile/tss");
    dispatch({
      type: GET_TSS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};
// Add ADD_TSS
export const addTss = (tss) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.post("/api/profile/tss", tss, config);
    console.log(res.data);
    dispatch({
      type: ADD_TSS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

// Edit or Update Tss
export const updateTss = (tss) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.put("/api/profile/tss", tss, config);
    dispatch({
      type: UPDATED_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

//  Update Profile
export const updateProfile = (formData) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.put("/api/profile", formData, config);
    dispatch({
      type: ADD_SCHOOL_TO_MEMBER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};

export const changeImage = (Image) => async (dispatch) => {
  const formData = new FormData();
  formData.append("Avatar", Image);
  const options = {
    onUploadProgress: (ProgressEvent) => {
      const { loaded, total } = ProgressEvent;
      let percent = Math.floor((loaded * 100) / total);
      console.log(`${loaded}kb of ${total}kb | ${percent}% `);
      dispatch({
        type: UPLOAD_PROGRESS,
        payload: { loaded, total, percent },
      });
      if (percent === 100) {
        setTimeout(() => dispatch({ type: REMOVE_PROGRESS }), 8000);
      }
    },
  };
  const res = await axios.post("/api/profile/image", formData, options);
  try {
    dispatch({
      type: UPDATED_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};
