import React, { useState } from "react";
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import { register } from "../../../actions/Members.actions";
import { setAlert } from "../../../actions/alert";
import { PlusOutlined } from '@ant-design/icons';

import { Modal, Button } from 'antd';

const AddMember = ({ register , departmentId , admin }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
    nid: "",
    brthday: "",
    sex: "",
    adress: "",
    nationality: "",
    phone: "",
    departmentID : departmentId ? departmentId : ""
  });

  const {
    name,
    email,
    password,
    password2,
    nid,
    brthday,
    sex,
    adress,
    nationality,
    phone,
    departmentID
  } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      setAlert("كلمات المرور التي ادخلتها غير متطابقة", "danger");
    } else {
      register(formData);
      setFormData({
        name: "",
        email: "",
        password: "",
        password2: "",
        nid: "",
        brthday: "",
        sex: "",
        adress,
        nationality: "",
        phone: "",
      });
      setAlert("تم إضافة عضو هيئة التدريس بنجاح", "success");
      setIsModalVisible(false);
    }
  };
  return (
    <>
      {/* eslint-disable-next-line */}
      <a type="primary" onClick={showModal} >
       {departmentId && departmentId!== "" ?        
        <Button type="primary" shape="circle" icon={<PlusOutlined />} size={64} /> :
        <div className="links">
        <img src="/img/teacher.png" height="350" width="250" alt="ibt" />
        <h3 className="center-align lead text-white">عضو هيئة تدريس</h3>    
         </div>
        } 
      </a>

      <Modal  visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
       footer={[
        <Button key="submit" type="primary"  onClick={onSubmit}>
        تسجيل
      </Button>,
        <Button key="back" onClick={handleCancel}>
          إلغاء
        </Button>,

      ]}
      >
      <div className="sign-up container">
              <h2 className="title center">
                تسجيل استاذ في منظومة البوابة الإلكترونية
              </h2>

              <form className="sign-up-form  my-5" onSubmit={onSubmit}>
                <FormInput
                  type="text"
                  name="name"
                  value={name}
                  onChange={onChange}
                  label="إسم الاستاذ"
                  required
                />
                <FormInput
                  type="email"
                  name="email"
                  value={email}
                  onChange={onChange}
                  label="البريد الإلكتروني"
                  required
                />
                <FormInput
                  type="password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  label="كلمة المرور"
                  required
                />
                <FormInput
                  type="password"
                  name="password2"
                  value={password2}
                  onChange={onChange}
                  label="تأكيد كلمة المرور"
                  required
                />
                <FormInput
                  type="text"
                  name="nid"
                  value={nid}
                  onChange={onChange}
                  label="الرقم الوطني"
                  required
                />

                <FormInput
                  type="date"
                  name="brthday"
                  value={brthday}
                  onChange={onChange}
                  label="تاريخ الميلاد"
                  required
                />
                <label for="sex">اختر الجنس</label>

                <select
                  type="text"
                  name="sex"
                  value={sex}
                  onChange={onChange}
                  label="الجنس"
                  required
                  id="sex"
                >
                  <option value="">إختر الجنس</option>
                  <option value="ذكر">ذكر</option>
                  <option value="انثي">انثي</option>
                </select>

                <FormInput
                  type="text"
                  name="nationality"
                  value={nationality}
                  onChange={onChange}
                  label="الجنسية"
                  required
                />

                <FormInput
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={onChange}
                  label="رقم الهاتف"
                />
                <FormInput
                  type="text"
                  name="adress"
                  value={adress}
                  onChange={onChange}
                  label="عنوان السكن"
                  required
                />
              </form>
            
            </div>
      </Modal>
    </>
  );
};


export default connect(null, {
  register,
})(AddMember);
