import React, { useEffect } from 'react'
import { Fragment } from 'react'
import {useSelector , useDispatch} from "react-redux"
import Spinner from '../../components/Layout/Spinner'
import {getSchools} from "../../actions/school.action"
import AddSchool from "../../components/modals/School/AddSchool"
import SchoolItems from './SchoolItems'
import { Divider} from "antd"
import Container from "./Container"
import StudyYearsPage from "../../dashBourd/StudyYears/StudyYears.page"

const Schools = () => {
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getSchools())
    // eslint-disable-next-line
  },[])
 const schooles = useSelector(state => state.schools)
 const user = useSelector(state => state.auth.user)
 const {loading , schools} = schooles

    return loading ? <Spinner /> : (
            <Fragment>
              <Container>
              <div className="container-fluid">
              <Divider orientation="left">
                  <h1> أقسام الكلية  {user && user.Admin && (
                    <Fragment>
                      <AddSchool />
                    </Fragment>
                  )}</h1>
                </Divider>
                  <div className="container center-align ">
                  </div>
                  <table className="striped container-fluid ">
                    <thead>
                      <tr>
                        <th className="right-align">رمز القسم</th>
                        <th className="right-align">  القسم</th>
                        <th className="right-align"> رقم الهاتف</th>
                        <th className="right-align"> لغة التدريس</th>
                        <th className="right-align"> رمز الانتساب</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      
                        { schools && schools.map((school) => (
                            <Fragment key={school._id}>
                              <SchoolItems school={school} />
                            </Fragment>
                          ))} 
                    </tbody>
                  </table>
                  <br />
                  
                  <StudyYearsPage  />
                </div>
               
                  </Container>
            </Fragment>
    )
}

export default Schools
