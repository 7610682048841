import axios from "axios";
import {
  GET_STUDENTS,
  ADD_STUDENT,
  ERROR_STUDENT,
  UPDATE_STUDENT,
  DELETE_STUDENT,
  FILTER_STUDENTS,
  USER_LOADED,
} from "./types";

// Register Student
export const register = ({
  nid,
  name,
  brthday,
  sex,
  nationality,
  adress,
  department,
  sid,
  email,
  password,
  departmentID
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    nid,
    name,
    brthday,
    sex,
    nationality,
    adress,
    department,
    sid,
    email,
    password,
    departmentID
  });

  try {
    const res = await axios.post("/api/students", body, config);

    dispatch({
      type: ADD_STUDENT,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_STUDENT,
      payload: { msg: error.response, status: error.response },
    });
  }
};

export const updateStudent = (formData) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.put(
      `/api/students/${formData._id}`,
      formData,
      config
    );
    dispatch({
      type: UPDATE_STUDENT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_STUDENT,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
export const BlockingStudent = (_id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/students/${_id}/block`);
    dispatch({
      type: UPDATE_STUDENT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_STUDENT,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};


// get Students by department 
export const getStudentsBySchool = (_id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/students/department/${_id}`);
    dispatch({
      type: GET_STUDENTS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_STUDENT,
      payload: { msg: error.response, status: error.response },
    });
  }
};

// get Students by Class 
export const getStudentsByClass = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/students/class/${id}`);
    dispatch({
      type: GET_STUDENTS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_STUDENT,
      payload: { msg: error.response, status: error.response },
    });
  }
};

// GET CONFERANCES
export const getStudents = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/students");
    dispatch({
      type: GET_STUDENTS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_STUDENT,
      payload: { msg: error.response, status: error.response },
    });
  }
};

export const deleteStudent = (id) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const res = await axios.delete(`/api/students/${id}`);
    dispatch({
      type: DELETE_STUDENT,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_STUDENT,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
//Reset Password
export const ResetPassword = (_id) => async (dispatch) => {
  try {
    await axios.put(`/api/students/${_id}/resetpassword`);
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_STUDENT,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
export const sendEmals = (myFilters, message) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    // eslint-disable-next-line
    const res = await axios.post(
      `/api/students/sendemail`,
      { emails: myFilters, message: message },
      config
    );
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_STUDENT,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
// add Subjects To Student
export const adddSubjectToStudent = (data, id) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.post(
      `/api/students/subjects/${id}`,
      { subjects: data },
      config
    );

    dispatch({
      type: UPDATE_STUDENT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_STUDENT,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
//Delete Subjects from student
export const deleteSubjectToStudent = (data, id) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.post(
      `/api/students/subjects/${id}/delete`,
      { subjects: data },
      config
    );

    dispatch({
      type: UPDATE_STUDENT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_STUDENT,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};


export const removeStudentfromClasses = () => async (dispatch) => {
  try {
    const {data} = await axios.put(`/api/studyears/allarchive`);
    dispatch({
      type: GET_STUDENTS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_STUDENT,
      payload: { msg: error.response, status: error.response&& error.response.status },
    });
  }
};
// filter Students
export const filterStudents = (text) => (dispatch) => {
  dispatch({ type: FILTER_STUDENTS, payload: text });
};


export const affiliationToSchool = (_id ,departmentID) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/students/${_id}/department` , {departmentID});
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_STUDENT,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};