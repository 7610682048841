import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { getStudentsByClass, getStudentsBySchool } from "../../actions/Student.action";
import AddStudent from "../../components/modals/students/SignUpStudent";
import StudentItems from "./StudentItems";
import StudentFilter from "../../components/students/StudentFilter";
import Spinner from "../../components/Layout/Spinner";
import { getSubjects } from "../../actions/Subjects.action";
import SendEmail from "../../components/modals/students/SendEmail";

const StudentsPage = ({
  getStudentsBySchool,
  getStudentsByClass,
  school,
  getSubjects,
  students: { students, filtered, loading },
  Class,
  user,
}) => {
  useEffect(() => {
    Class &&  Class.length > 0 ? getStudentsByClass(Class) : getStudentsBySchool(school._id)
    !Class && getSubjects(school._id);
    console.log(Class)
    // eslint-disable-next-line
  }, [loading]);
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container-fluid" style={{direction : "rtl"}}>
          {/* <div className="container center-align ">
            <StudentFilter />
            <SendEmail />
          </div> */}
          
          {user.departments && user.departments.length > 0 && 
               user.departments.map(s => s.id._id.toString() === school._id.toString() && s.roll==="admin" && <AddStudent schoolId = {school._id} />)}
          <table className="striped container-fluid ">
            <thead>
              <tr>
                <th className="right-align">الرقم الوطني</th>
                <th className="right-align"> الاسم</th>
                <th className="right-align"> رقم القيد</th>
                <th className="right-align"> الجنسية</th>
                <th className="right-align"> عنوان السكن</th>
                <th className="right-align"> القسم</th>
                <th className="right-align"> البريد الالكتروني</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {filtered.length > 0
                ? filtered.map((student) => (
                    <Fragment key={student && student._id}>
                      <StudentItems student={student} Class={Class} />
                    </Fragment>
                  ))
                : students && students.map((student) => (
                    <Fragment key={ student &&  student._id}>
                      <StudentItems student={student} Class={Class} />
                    </Fragment>
                  ))}
            </tbody>
          </table>
        </div>
     
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  students: state.students,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  school : state.schools.school
});
export default connect(mapStateToProps, {
  getStudentsBySchool,
  getSubjects,
  getStudentsByClass
})(StudentsPage);
